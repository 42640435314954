import Vue from "vue";
import Vuex from "vuex";
Vue.use(Vuex);
import * as api from "@/api";

// 创建一个新的 store 实例
const store = new Vuex.Store({
  strict: process.env.NODE_ENV != "production",
  state: {
    // bot 消息对象
    // 每条消息的 id 为键, needsAnimation (Boolean) 为值
    botMessage: {},
    // 用户对话列表
    messages: [],
    //会话id
    /**
     * {
     *  conversationId: string,
     *  webSocketId: string
     * }
     */
    conversation: null,
    //用户信息
    user: null,
    loadingUser: false,
  },
  getters: {
    user: (state) => {
      return state.user;
    },
  },
  actions: {
    //获取用户信息
    async getUserInfo({ commit }) {
      commit("setLoadingUser", true);
      const user = await api.getUserDetail();
      sessionStorage.setItem("user", JSON.stringify(user));
      commit("setUser", user);
      commit("setLoadingUser", false);
    },
    saveMessages({ commit }, messages) {
      commit("setMessages", messages);
    },
    updateLastMessage({ commit }, message) {
      commit("setLastMessageContent", message);
    }
  },
  mutations: {
    setLastMessageContent(state, message) {
      const messages = state.messages;
      if (messages.length > 0) {
        messages[messages.length - 1] = message;
      }
    },
    setUser(state, payload) {
      state.user = payload;
    },
    setLoadingUser(state, payload) {
      state.loadingUser = payload;
    },
    setMessages(state, payload) {
      state.messages = payload;
    },
    setConversation(state, payload) {
      state.conversation = payload;
    },
    /**
     *
     * @param {*} state
     * @param {*} payload
     * @example {id: string, needsAnimation: boolean}
     *
     * 新增 bot message
     */
    addBotMessage(state, payload) {
      Vue.set(state.botMessage, payload.id, payload.needsAnimation);
    },
    /**
     *
     * @param {*} state
     * @param {*} payload messageId
     *
     * 传入 messageId 删除
     */
    removeBotMessage(state, payload) {
      Vue.delete(state.botMessage, payload);
    },
    /**
     *
     * @param {*} state
     * @param {*} payload {id: string, needsAnimation: boolean}
     *
     * 更新 bot message needsAnimation
     */
    updateBotMessage(state, payload) {
      if (payload.id in state.botMessage) {
        // console.log("已存在 " + payload.id);
        state.botMessage[payload.id] = payload.needsAnimation;
      } else {
        // console.log("不存在" + payload.id);
        Vue.set(state.botMessage, payload.id, payload.needsAnimation);
      }
    },
    /**
     * 取消已有的 bot message 打字机动画
     */
    setAllBotMessageNoAnimation(state) {
      for (const key in state.botMessage) {
        state.botMessage[key] = false;
      }
    },
    /**
     * 清空 bot message
     */
    clearBotMessage(state) {
      state.botMessage = {};
    },
  },
  //   actions: {
  //     increment({ commit }, n) {
  //       commit("increment", n);
  //     },
  //   },
  //   getters: {},
  //   modules: {},
});
export default store;
