import Vue from "vue";
import VueRouter from "vue-router";
import App from "./App.vue";

import router from "@/router";
import store from "@/store";
import CustomMessage from "./custom-plugins/custom-message/CustomMessage.js";

import {
  Upload,
  Cascader,
  Tooltip,
  Input,
  Button,
  Select,
  Switch,
  Pagination,
  Dropdown,
  DropdownMenu,
  DropdownItem,
  Table,
  TableColumn,
  Loading,
  Row,
  Col,
  Header,
  Image,
  Message,
  Option,
  Scrollbar,
  Checkbox,
  CheckboxGroup,
} from "element-ui";

import { Tabs, TabPane, Slider } from "element-ui";

import VueTypedJs from "vue-typed-js";

Vue.use(VueTypedJs);

Vue.use(Tabs);
Vue.use(TabPane);
Vue.use(Slider);

Vue.use(Button);
Vue.use(Select);
Vue.use(Switch);
Vue.use(Pagination);
Vue.use(Dropdown);
Vue.use(DropdownMenu);
Vue.use(DropdownItem);
Vue.use(Table);
Vue.use(TableColumn);
Vue.use(Loading);
Vue.use(Row);
Vue.use(Col);
Vue.use(Header);
Vue.use(Image);
Vue.prototype.$message = Message;
Vue.use(Option);
// Vue.use(OptionGroup)
Vue.use(Scrollbar);
Vue.use(Input);
Vue.use(Tooltip);
Vue.use(Cascader);
Vue.use(Upload);
Vue.use(Checkbox);
Vue.use(CheckboxGroup);

Vue.use(CustomMessage);

Vue.use(VueRouter);
Vue.config.productionTip = false;

new Vue({
  el: "#app",
  router: router,
  store: store,
  // store,
  render: (h) => h(App),
});

console.log("v20210714build001");
